/* eslint-disable react/prop-types */
/** @jsx jsx */
import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Box, Heading, jsx, AspectRatio, Button } from 'theme-ui';
import PortableText from '../../components/PortableText';
import { SEO } from '../../utils';
import SermonCard from '../../components/SermonCard';
import Pagination from '../../components/Pagination';
import SermonButton from '../../components/SermonButton';
import ContentContainer from '../../components/content-container';

export const SermonLabel = ({ children }) => (
  <span sx={{ fontFamily: 'heading', fontSize: 0, color: 'primary' }}>
    {children}
  </span>
);

export const Page = ({ data: { page, sermons }, pageContext }) => {
  const ternaryObjectStyles = {
    objectPosition: page?.mainImage?.hotspot
      ? `${page.mainImage.hotspot.x * 100}% ${page.mainImage.hotspot.y * 100}%`
      : 'center center',
  };

  return (
    <>
      <SEO title={page.title} />
      <Box>
        {page?.mainImage?.asset?.fluid ? (
          <>
            <Img
              fluid={page.mainImage.asset.fluid}
              sx={{
                maxHeight: ['600px'],
              }}
              imgStyle={ternaryObjectStyles}
            />
          </>
        ) : (
          <AspectRatio
            ratio={20 / 2}
            sx={{
              p: 4,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'background',
              bg: 'primary',
            }}
          ></AspectRatio>
        )}
        <ContentContainer>
          <Heading
            as="h1"
            sx={{ py: 3, fontFamily: 'fancyFont', fontSize: [5, '3.3rem'] }}
          >
            {page.title}
          </Heading>
          <PortableText blocks={page._rawBody} />
          <SermonButton to="/sermon-series/" text="Sermon Series" />

          {sermons.nodes.map(sermon => (
            <SermonCard key={sermon.id} sermon={sermon} />
          ))}
          <Pagination
            pageSize={parseInt(process.env.GATSBY_PAGE_SIZE || '7')}
            currentPage={pageContext.currentPage || 1}
            totalCount={sermons.totalCount}
            base="/sermons"
          />
        </ContentContainer>
      </Box>
    </>
  );
};

export default Page;

export const query = graphql`
  query($skip: Int = 0, $pageSize: Int = 7) {
    page: sanityPage(slug: { current: { eq: "sermons" } }) {
      title
      _rawBody(resolveReferences: { maxDepth: 5 })
      mainImage {
        hotspot {
          x
          y
        }
        asset {
          fluid(maxWidth: 1280) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
    sermons: allSanitySermon(
      sort: { fields: preachedDate, order: DESC }
      limit: $pageSize
      skip: $skip
    ) {
      totalCount
      nodes {
        title
        id
        slug {
          current
        }
        passage
        preachedDate
        series {
          title
          image {
            asset {
              fixed(width: 250, height: 156) {
                ...GatsbySanityImageFixed
              }
            }
          }
        }
        speaker {
          name
          image {
            asset {
              fixed(width: 250, height: 156) {
                ...GatsbySanityImageFixed
              }
            }
          }
        }
      }
    }
  }
`;
