/* eslint-disable react/prop-types */
/** @jsx jsx */
import React from 'react';
import Img from 'gatsby-image';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { Box, jsx, Flex } from 'theme-ui';
import { format } from 'date-fns';

export const SermonLabel = ({ children }) => (
  <span sx={{ fontFamily: 'heading', fontSize: 0, color: 'primary' }}>
    {children}
  </span>
);

export default function SermonCard({ sermon }) {
  const sermonDate = format(new Date(sermon.preachedDate), 'MMMM d, yyyy');

  const data = useStaticQuery(graphql`
    query {
      sermonImage: file(relativePath: { eq: "sermon.jpg" }) {
        childImageSharp {
          fixed(width: 250, height: 156, cropFocus: CENTER) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  function setSermonImage() {
    if (sermon?.series?.image?.asset?.fixed) {
      const sermonCardImage = sermon.series.image.asset.fixed;
      return sermonCardImage;
    }
    if (sermon?.speaker?.image?.asset?.fixed) {
      const sermonCardImage = sermon.speaker.image.asset.fixed;
      return sermonCardImage;
    }
    const sermonCardImage = data.sermonImage.childImageSharp.fixed;
    return sermonCardImage;
  }

  const sermonImage = setSermonImage();

  return (
    <Link
      to={`/sermons/${sermon.slug.current}`}
      sx={{ textDecoration: 'none', color: 'text' }}
    >
      <Flex
        as="article"
        sx={{
          p: 2,
          flexWrap: ['wrap', 'nowrap', 'nowrap'],
          justifyContent: 'center',
          boxShadow: ['xlcomplete', null, 'none'],
          borderRadius: ['xl', null, 'none'],
          mt: 2,
          ':hover': { bg: 'navbggrey' },
        }}
      >
        <Img
          fixed={sermonImage}
          alt={sermon.title}
          sx={{ borderRadius: 'lg', flex: '0 0 250px' }}
        />
        <Box
          sx={{ flex: '0 1 500px', pl: 3, lineHeight: '24px', mt: [2, 2, 1] }}
        >
          <h3 sx={{ my: 0 }}>{sermon.title}</h3>
          {sermon?.series?.title && (
            <h5 sx={{ mt: 0, mb: 1, color: 'primary' }}>
              {sermon.series.title}
            </h5>
          )}
          <p sx={{ fontSize: 1 }}>
            <SermonLabel>Preacher:</SermonLabel> {sermon.speaker.name}
            <br />
            <SermonLabel>Date:</SermonLabel> {sermonDate}
            <br />
            <SermonLabel>Passage:</SermonLabel> {sermon.passage}
          </p>
        </Box>
      </Flex>
    </Link>
  );
}
