/** @jsx jsx */
/* eslint-disable react/prop-types */
import React from 'react';
import { jsx, Link } from 'theme-ui';
import { Link as GatsbyLink } from 'gatsby';

export default function Pagination({
  currentPage,
  pageSize,
  totalCount,
  base,
}) {
  const pageCount = Math.ceil(totalCount / pageSize);
  const prevPage = currentPage - 1;
  const nextPage = currentPage + 1;
  const hasNextPage = nextPage <= pageCount;
  const hasPrevPage = prevPage >= 1;

  return (
    <div
      sx={{
        display: 'flex',
        textAlign: 'center',
        alignContent: 'center',
        alignItems: 'center',
        justifyItems: 'center',
        border: 1,
        borderColor: 'muted',
        my: 2,
        borderRadius: 'md',
        '& > *': {
          padding: 1,
          flex: '1',
          borderRight: 1,
          borderRightColor: 'muted',
          textDecoration: 'none',
          '&[aria-current], &.current': {
            color: 'secondary',
          },
          '&[disabled]': {
            pointerEvents: 'none',
            color: 'muted',
          },
        },
        '.word': {
          display: ['none', null, 'inline'],
          fontSize: [0, null, 1],
        },
      }}
    >
      <Link as={GatsbyLink} disabled={!hasPrevPage} to={`${base}/${prevPage}`}>
        ⭠<span className="word"> Previous</span>
      </Link>
      {Array.from({ length: pageCount }).map((_, i) => (
        <Link
          key={i}
          as={GatsbyLink}
          className={currentPage === 1 && i === 0 ? 'current' : ''}
          to={`${base}/${i > 0 ? i + 1 : ''}`}
        >
          {i + 1}
        </Link>
      ))}
      <Link as={GatsbyLink} disabled={!hasNextPage} to={`${base}/${nextPage}`}>
        <span className="word">Next </span>⭢
      </Link>
    </div>
  );
}
